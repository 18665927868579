'use strict'
import './css/styles.scss'

import adminMenu from '@elasticms/admin-menu'
import back2top from './js/back2top'
import ajaxSearch from "./js/ajax-search";
import smalsToc from '@smals-webagency/toc/smals-toc-jquery-bundle';
// import externalLink from "./js/external-link";
// import forms from "./js/forms";
import { NavBar } from './js/navbar'
import { collapse_text } from './js/collapse-text.js';
import datepicker from './js/datepicker'
import Cookies from 'js-cookie'
import cookiesBanner from "./js/cookiesBanner"

const $ = require('jquery')
window.$ = $
window.jQuery = $
require('bootstrap')
const enquire = require('enquire.js');


adminMenu()
back2top()
ajaxSearch()
// externalLink();
// forms();
const navBar = new NavBar()
navBar.activateBestItem()
console.log('Inami website loaded!')

$(document).ready(function () {
    datepicker()
    cookiesBanner(Cookies)

    $('#toc').smalsToc({
        contentElementId: 'toc-content',
        listClass: 'summaryLinks',
        anchorClass: 'text-decoration-none',
        headings: 'h2'
    })

    collapse_text.init()

    /* START lexion */
    function applyLexicon($element, key, value) {
        var full_text = $element.text();
        var full_text_replace = full_text.replace(new RegExp("\\b(" + key + ")\\b","mi"), "<abbr data-toggle=\"tooltip\" data-placement=\"top\" title=\""+ value +"\">$&</abbr>");
        $element.replaceWith(full_text_replace);
    }

    var url = $('#page-content').data('ajax-lexicon');
    self.xhr = $.getJSON(url, {
    }).done(function(data) {
        $.each(data, function(key, value) {
            let notFound = true;
            $('#page-content').find('.content-lexicon').find('p').contents().each(function() {
                var regex = new RegExp("\\b(" + key + ")\\b","mi")
                if (this.nodeType === 3) { // text
                    if (regex.test($(this).text()) && notFound) {
                        notFound = false;
                        applyLexicon($(this), key, value);
                    }
                } else if (this.nodeName === 'SPAN') {
                    if (regex.test($(this).contents().first().text()) && notFound) {
                        notFound = false;
                        applyLexicon($(this).contents().first(), key, value);
                    }
                }
            });
        });

        $('[data-toggle="tooltip"]').tooltip();
    }).fail(function() {
        console.log( 'error with lexicon' );
    });
    /* END lexicon*/

    // move filters desktop & mobile
    const $formFilters = $('#filters-desktop > form');

    enquire.register("screen and (max-width:767.98px)", {
        match : function() {
            $formFilters.detach().appendTo('#filters-mobile');
            console.log('match');
        },
        unmatch :function() {
            $formFilters.detach().appendTo('#filters-desktop');
            console.log('unmatch');
        }
    });

    // Nomensoft
    $("#NomensoftButtonfr").click(function(){ location.href='https://webappsa.riziv-inami.fgov.be/Nomen/fr/'+$("#NomensoftNumber").val()});
    $("#NomensoftButtonnl").click(function(){ location.href='https://webappsa.riziv-inami.fgov.be/Nomen/nl/'+$("#NomensoftNumber").val()});

    // Alerts
    $('.notification .arrow').click(function(e) {
        e.preventDefault();
        let $alert = $(this).parent('li');
        if($alert.hasClass('alert-hidden')) {
            $alert.removeClass('alert-hidden');
        } else {
            $alert.addClass('alert-hidden');
        }
    });
})
