const $ = require('jquery')
window.jQuery = $

require('bootstrap-datepicker')
require('bootstrap-datepicker/js/locales/bootstrap-datepicker.nl')
require('bootstrap-datepicker/js/locales/bootstrap-datepicker.fr')

export default function datepicker () {
    $('.datepicker').each(function () { $(this).datepicker('destroy') })
    $('div.date-range').each(function () {
        const inputClass = $(this).data('input-class')
        const locale = $(this).data('language')

        $(this).datepicker({
            format: 'dd/mm/yyyy',
            theme: 'bootstrap',
            todayHighlight: true,
            autoclose: true,
            language: locale,
            clearBtn: true,
            inputs: $('.' + inputClass),
            keepEmptyValues: true
        })

        $(this).find('.btn-show-datepicker').on('click', function () {
            const datepickerId = $(this).data('datepicker')
            $(datepickerId).datepicker('show')
        })
    })
}