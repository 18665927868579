const collapse_text = {
    init: function() {
        const $filters = $('.search-filters');

        if($filters.length) {
            const $checkboxLink = $filters.find('.search-filter-group [data-toggle="collapse"]');
            this.toggleText($checkboxLink);
        }
    },
    toggleText: function($collapseLink) {
        $collapseLink.each(function( i, el ) {
            const $target = $(el).attr('href');
            const $elementId = $(el).attr('id');
            const $textShow = $(el).text();
            const $textHide = $(el).attr('data-text');

            $(document).on('show.bs.collapse',$target, function () {
                $('#'+ $elementId).text($textHide).attr('data-text',$textShow);
            });
            $(document).on('hide.bs.collapse',$target, function () {
                $('#'+ $elementId).text($textShow).attr('data-text',$textHide);
            });

            // move focus
            $(document).on('shown.bs.collapse',$target, function () {
                const $targetFocus = $($target).find('li:first-child a');
                $targetFocus.focus();
            });
        });
    }
}

export { collapse_text };