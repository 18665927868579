export class NavBar {
  constructor (selectors = ['header nav.navbar'], activeMessage = '(active)', parentsNodeName = 'LI') {
     console.log(selectors);
    this.selectors = selectors
    this.activeMessage = activeMessage
    this.parentsNodeName = parentsNodeName
  }

  activateBestItem () {
    if (window.location.pathname === '/' || window.location.pathname === document.documentElement.getAttribute('data-base-url')) {
      return
    }

    const selectors = []
    this.selectors.forEach(function (input) {
      selectors.push(input + ' a')
    })

    const bestItems = document.querySelectorAll(selectors.join(','))
    if (bestItems.length <= 0) {
      return
    }

    let bestItem = null
    let path = window.location.href
    bestItems.forEach(function (item) {
      if (window.location.pathname === item.href || bestItem == null || (path.indexOf(item.href) > -1 && item.href.length > bestItem.href.length)) {
        bestItem = item
      }
    })

    const node = document.createElement('span')
    node.classList.add('sr-only')
    const textNode = document.createTextNode(this.activeMessage)
    const spaceNode = document.createTextNode(' ')
    node.appendChild(textNode)
    bestItem.appendChild(spaceNode)
    bestItem.appendChild(node)

    for (let current = bestItem.parentNode; current !== null; current = current.parentNode) {
      if (this.parentsNodeName !== current.nodeName) {
        continue
      }
      current.classList.add('active')
    }
  }
}
